<template>
  <div class="groupPurchasing-purchase">
    <h1>共同購入</h1>

    <UiLine />
    <div class="buy">
      <img :src="`${rootUrl}/img/JHI-h_024.jpg`" alt="商材アイテム" />

      <p>
        共同購買とは、組合にて一括して事務処理を行ったり、購入を取りまとめたりすることでスケールメリットを実現し、組合員様の事業経営の合理化や経費削減に寄与していくことを目的としております。
        当組合では組合員様が需要されるマスクやコロナ対策グッズなどの共同購入サービスをご提供しています。組合員様が必要とされる資材や備品のコスト削減、安定供給、新商品のご提案・ご提供や各種サービスの情報提供など、共同購買商材の展開をさせていただきます。
      </p>
    </div>
    <div class="buy-2">
      <div class="left">
        <p>
          現在取り扱いのある商材については、「プリーツマスク」や「塩ビ製の飛沫パネル」といったコロナ感染対策グッズをはじめとする商材を組合員様にご紹介させていただいております。<br />
          今後も商材アイテムは増やす方向でおりますので、お気軽にお問合せ下さい。
        </p>
        <div class="button">
          <UiDetailButton :path="`/`" link="トップページに戻る" />
        </div>
      </div>

      <img class="pic" :src="`${rootUrl}/img/JHI-h_025.jpg`" alt="共同購入" />

      <div class="button-sp">
        <UiDetailButton :path="`/`" link="トップページに戻る" />
      </div>
    </div>
  </div>
</template>

<script>
import UiDetailButton from "@/components/Ui/DetailButton.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "GroupPurchasingPurchase",
  components: {
    UiDetailButton,
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.buy {
  img {
    width: 100%;
  }
  P {
    padding-top: 10px;
  }
}

.buy-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  img,
  .left {
    width: 48%;
    height: auto;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .button {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .button-sp {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
    }
  }
}

.pic {
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: flex-start;
  }
}
</style>