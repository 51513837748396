<template>
  <div class="contact-form">

    <div class="wrap">
      <CotacntFormContact class="space-1" />
    </div>
    
    <UiBar2 title="お電話でのお問い合わせ"/>

    <div class="wrap">
      <CotacntFormTel style="padding-top: 110px;" />
    </div>

    <UiBar2 title="メールでのお問い合わせ" class="space-3" />
    
    <div class="wrap">
      <CotacntFormMail id="contact" :type="0" class="space-3" />
    </div>
    
  </div>
</template>

<script>
import CotacntFormContact from "@/components/CotacntForm/Contact.vue";
import UiBar2 from '@/components/Ui/Bar2.vue';
import  CotacntFormTel from '@/components/CotacntForm/Tel.vue';
import  CotacntFormMail from '@/components/CotacntForm/Mail.vue';


export default {
  name: "CotacntForm",
  components: {
    CotacntFormContact,
    UiBar2,
    CotacntFormTel,
    CotacntFormMail,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
    created() {
    const hash = this.$route.hash;
    // alert(hash);
    this.$nextTick(() => {
      this.$scrollTo(hash, 0, {});
    });
  },
};
</script>
<style lang="scss" scoped>
.space-3{
  padding-top: 110px;
  @media screen and (max-width: 767px){
           padding-top: 0px; 
          }
}

.bar-width{
  padding-top: 20px;
}



</style>