<template>
  <div class="access-philosophy">
   <h2>団体理念</h2>

   <UiLine />
<div class="img">
   <img :src="`${rootUrl}/img/JHI-h_026.jpg`" alt="団体理念" />
  </div>

  <h2 style="margin-top:100px;">代表あいさつ</h2>
   <UiLine />
   <div class="words">
<p>私達は、日本国全ての人達とアジアの未来の為に人・企業・国を繋ぎ子供たちにより良い未来を残していかなければならない。 <br class="sp-kaigyo"><br>JHI協同組合はアジアの発展に貢献し豊かな未来を目指していきます。</p>
   </div>
  
</div>
</template>

<script>
import UiLine from '@/components/Ui/Line.vue';
export default {
  name: "AccessPhilosophy",
  components: {
    UiLine,
    },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.img{
text-align: center;
img{
  width: 50%;

  @media screen and (max-width: 767px){
           width: 100%; 
          }
}
}

.words{
  width: 70%;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 767px){
           width: 100%; 
          }


}



</style>