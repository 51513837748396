<template>
  <div class="home-content">
    <h2>事業内容</h2>
    <UiLine />
    <div class="cover">
      <div class="item">
        <img
          :src="`${rootUrl}/img/JHI-h_003.jpg`"
          alt="外国人実習生の受け入れ"
        />
        <h3>外国人技能実習生の受け入れ</h3>
        <p>
          JHI協同組合では外国人技能実習制度に基づく技能実習生の共同受入を行っております。<br>
          受け入れ企業（実習実施者）様と技能実習生の雇用の契約や実習期間中の生活等を全力でサポートさせていただきますので、安心してご用命下さい。
        </p>
        <UiDetailButton class="position" :path="`/ginojisshusei`" link="詳細はコチラ"/>
      </div>

      <div class="item">
        <img
          :src="`${rootUrl}/img/JHI-h_004.jpg`"
          alt="特定技能外国人の受け入れ"
        />
        <h3>特定技能外国人の受け入れ</h3>
        <p>
          2019年から国内需要が高まり在留資格「特定技能」が始まり、2020年には日本全国で徐々に受け入れがスタートしております。<br>
          当組合も「登録支援機関」を取得し、「特定技能」のご対応が可能になりました。
        </p>
        <UiDetailButton class="position" :path="`/tokuteiginoshien`" link="詳細はコチラ"/>
      </div>

      <div class="item">
        <img :src="`${rootUrl}/img/JHI-h_005.jpg`" alt="共同購入" />
        <h3>共同購入</h3>
        <p>
          共同で購入することで、コストメリットがある商材を組合員様へご紹介させていただきます。<br>
          組合員様が抱えるコスト削減に対するニーズに応えるべく、経営コストの軽減と安定供給をご提案・ご提供させていただきます。
        </p>
        <UiDetailButton class="position" :path="`/group-purchasing`" link="詳細はコチラ"/>
      </div>
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";

export default {
  name: "HomeContent",
  components: {
    UiLine,
    UiDetailButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  justify-content: space-between;

@media screen and (max-width: 767px) {
  flex-direction: column;
}

  .item {
    width: 30%;
    position:  relative;
    padding-bottom: 30px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 50px;
    }

    img {
      width: 100%;
    }

    h3 {
      text-align: center;
      color: var(--green);
      font-size: 21px;
      font-weight: 21px;
      padding: 20px 0;
    }

    // p{
    //   padding-bottom: 30px;
    // }

    .position{
      position: absolute;
      right: 0;
      bottom: 0;

    }
  }
}
</style>