<template>
  <div class="ginojisshusei-flows">
    <h2>受入れの流れ</h2>

    <UiLine />

    <UiFlow
      :step="1"
      title="外国人技能実習生の受入お問合せ"
      descripton="`ホームページやご紹介等で外国人技能実習生の受入をご検討中、外国人技能実習生の受入について詳しく知りたいなどの場合には、お問い合わせ下さい。外国人技能実習生の受入に係る説明や組合の説明をさせていただきます。その際は貴社のご希望の条件等などにつきましてもヒアリングさせて下さい。`"
    />
    <UiFlow
      :step="2"
      title="組合への加入・技能実習生受入れ申込み"
      descripton="技能実習制度では実習実施責任者・技能実習指導員・生活指導員の選任や宿舎など決めなければならないことがありますので、事業申し込みの際にご説明させていただきます。"
    />
    <UiFlow
      :step="3"
      title="現地面接・選考"
      descripton="企業様自身で直接技能実習生候補者と面接し、ご納得いただいた上で人材を確定していただきます。確定すれば、実習生と受入契約を締結していただきます。"
    />
    <UiFlow
      :step="4"
      title="現地講習<br class='sp-kaigyo'/>（入国前講習）"
      descripton="送出し機関にて、技能実習生の選抜を実施し、受入技能実習生が決定した後には、日本語教育を実施いたします。日本での生活に必要な日本語を習得します。さらに、日本語だけではなく、日本の文化や生活についても学び、日本での技能実習や生活に備えます。規律ある生活を行えるよう指導して参ります。"
    />
    <UiFlow
      :step="5"
      title="<span class='sp-size'>技能実習計画認定申請と在留資格認定申請</span>"
      descripton="技能実習を開始する為に書類を作成しますが、当組合で作成の指導を行いますのでご安心下さい。在留資格認定証明書が交付されましたら、査証「ビザ」の申請や取得パスポート等も用意して、これらの書類入国時に提示して日本への入国となります。"
    />
    <UiFlow
      :step="6"
      title="入国"
      descripton="空港までの送迎は当組合で実施します。入国時は初めて来る日本に実習生は緊張をしておりますので、当組合が責任をもって入国後講習施設へ送迎いたします。"
    />
    <UiFlow
      :step="7"
      title="入国後講習施設"
      descripton="入国後は、円滑な技能実習に備え、日本での生活に慣れる為の講習を実施いたします。これは安全な技能実習を行う為には必要不可欠なものです。買い物の仕方、銀行の利用方法等の日本での日常生活を送るにあたっての基本的なことから、防犯面や火災、災害への対応方法についても、公的機関に講師をお願いして、指導を行って参ります。"
    />
    <UiFlow
      :step="8"
      title="配属、技能実習開始"
      descripton="一ヶ月の講習を経て、企業様に配属となります。準備期間が完了したら企業様へ配属し、技能実習を開始します。実習生にとっても、企業様にとってもここからが、実習生活の始まりです。組合でもしっかりとサポートを進めて参りますので、企業様におかれましてもしっかりとした指導を宜しくお願いいたします。１か月に１回の訪問指導と３か月に１回の監査訪問で実習実施者または技能実習生の不安や不満を取り除いて参ります。"
    />
    <UiFlow
      :step="9"
      title="技能検定試験<br class='sp-kaigyo'>（技能実習2号移行）"
      descripton="第2号技能実習に移行の予定者は、技能検定または技能実習評価試験の実技・学科試験の合格が必須になります。合格しますと、技能実習2号へ在留資格変更申請を行い、更なる技能の修得や習熟を図って行きます。"
    />
    <UiFlow
      :step="10"
      title="技能実習3号移行"
      descripton="技能実習３号に移行するためには、技能実習生自身が希望し、「技能評価試験（専門級）」等の試験に合格し、企業が「優良な実習実施者」の認定を受ける必要があります。技能実習生が合格し企業の認定が承認されると技能実習３号への移行ができます。但し、３号への移行は１カ月以上の一時帰国が必要です。"
    />
    <UiFlow
      :step="11"
      title="帰国"
      descripton="技能実習生は、講習期間と技能実習１号と技能実習２号計３年間又は、技能実習3号を合わせ5年間の技能実習を修了して帰国することとなります。技能実習生は帰国してから送出し機関（現地企業）に復職し、日本で学んだ技術、技能と経験を母国のために活かします。当組合職員は、帰国の指導や手配を行い、技能実習実施者の担当者と一緒に国際空港まで見送ります。"
    />
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
import UiFlow from "@/components/Ui/Flow.vue";
export default {
  name: "GinojisshuseiFlows",
  components: {
    UiLine,
    UiFlow,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/.sp-size{  
  @media screen and (max-width: 767px){
  
  font-size: 18px;
  }
}
</style>