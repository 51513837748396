<template>
  <div class="home-information">
    
    <UiLine />
    <table>
      <tr>
        <th>団体名</th>
        <td>JHI協同組合</td>
      </tr>
      <tr>
        <th>代表者名</th>
        <td>加藤&emsp;司</td>
      </tr>
      <tr>
        <th>所在地</th>
        <td>〒446-0076&emsp;愛知県安城市美園町<br
        class="sp-kaigyo"
      />二丁目18番地3</td>
      </tr>
      <tr>
        <th>電話番号</th>
        <td><a href="tel:0566953210" class="tel-no">0566-95-3210</a></td>
      </tr>
      <tr>
        <th>FAX</th>
        <td>0566-95-3211</td>
      </tr>
      <tr>
        <th>設立年月日</th>
        <td>2019年1月21日</td>
      </tr>

    </table>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "HomeInformation",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  width: 60%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  tr {
    border-top: solid 1px var(--black);

    th,
    td {
      padding: 20px;

      @media screen and (max-width: 767px) {
        border-bottom: none;
    display: block;
    width: 100%;
    padding: 0;
    text-align: center;
      }
    }

    th {
      width: 30%;
      color: var(--green);
      text-align: start;
      padding-left: 50px;

      @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        padding: 0;
        font-weight: bold;
        border-bottom: solid 1px var(--glay2);
        padding-top: 5px;
      }
    }
    td{
      @media screen and (max-width: 767px) {
        text-align: center;
        padding-bottom: 5px;
      }
    }
  }
  tr:last-child{
    td,th{
      border-bottom: solid 1px var(--black);

      @media screen and (max-width: 767px) {
    width: 100%;
      }

    }

    th{
      @media screen and (max-width: 767px) {
        border-bottom: solid 1px var(--glay2);
      }
    }
  }
}

.tel-no{
  
        text-decoration: none;
        color: black;
        pointer-events: none;
        cursor: default;

         @media screen and (max-width: 1034px) {
          pointer-events: initial;
          cursor: pointer;
        }
      
}
</style>