<template>
  <div class="ginojisshusei-About">
    <h2>外国人技能実習制度とは</h2>

    <UiLine />

    <img :src="`${rootUrl}/img/JHI-h_007.jpg`" alt="外国人技能実習制度とは" />

    <p>
      外国人技能実習制度とは、開発途上国等に対し、世界でも屈指である日本の技能、技術又は知識の移転を図り、経済発展を担う「人づくり」に協力することを目的としております。技能実習生は日本で修得した技能、技術又は知識を帰国後に母国で発揮することにより、自身の職業生活の向上や母国の産業・企業の発展に貢献することができます。
    </p>

    <div class="merit">
      <h2>外国人技能実習制度活用によるメリット</h2>

      <UiLine />
      <img :src="`${rootUrl}/img/JHI-h_008.jpg`" alt="外国人技能実習制度活用によるメリット" />

      <div class="description">
        <div class="box">
          <p class="title">①職場の活性化</p>
          <p class="sentence">
            技能実習により「技術の習得をする」という強い目的意識を持った外国人技能実習生は、技術習得の意欲も旺盛で、効率ＵＰに繋がるだけでなく、社員をはじめパート・アルバイトの方にまで良い刺激を与え、職場が活性化します。
          </p>
        </div>
        <div class="box">
          <p class="title">②企業様の国際貢献</p>
          <p class="sentence">
            日本の技能、技術又は知識を修得した技能実習生が、帰国後それらを活用し優秀な人材になる事で、母国に貢献することになります。
          </p>
        </div>
        <div class="box">
          <p class="title">③企業様の国際交流の推進</p>
          <p class="sentence">
            外国人技能実習生は、日本の文化や日本語を学んで帰国しますので、国際友好に大きく貢献します。母国企業でのリーダーを育て技能支援することにより、現地法人との取引拡大の可能性が広がります。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "GinojisshuseiAbout",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.merit {
  padding-top: 197px;

@media screen and (max-width: 767px){
  padding-top: 100px;
}


  .description {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;

    @media screen and (max-width: 767px){
      flex-direction: column;
    }

    .box {
      width: 31%;

      @media screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 20px;
        line-height: 1.7em;
      }

      .title {
        text-align: center;
        color: var(--green);
        font-weight: bold;
        font-size: 23px;
        margin-bottom: 10px;
        
      }
    }
  }
}
</style>