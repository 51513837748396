<template>
  <div class="line">
   <div class="black-line"></div>
  </div>
</template>

<script>
export default {
  name: "UiLine",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.black-line{
border-bottom: solid 2px rgb(62, 58, 57);
margin: 37px auto 55px auto;
width: 78px;
}

</style>