<template>
  <div class="thanks">
    <div class="wrap">
      <div class="box">
        <h1>お問い合わせを承りました</h1>
        <p>
          お問い合わせを承りました。<br>お問い合わせ内容を、ご入力のメールアドレスに送信しました。20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
      </div>
      <div class="button">
        <router-link to="/">トップへ戻る</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactFormComplete",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  margin-top: 100px;

  h1 {
    color: var(--green);
    font-size: 31px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 20px;

    br{
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
.button {
  display: flex;
  margin-top: 50px;
  a {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    padding: 13px 30px;
    text-decoration: none;
    color: white;
    border: solid 2px;
    transition: 0.4s;
    background-color: var(--green);

    @media screen and (max-width: 767px) {
      width: 40%;
    }

    &:hover {
      background-color: var(--glay);
    }
  }


}
</style>