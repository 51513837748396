<template>
  <div class="ginojisshusei-stay">
    <UiBar title="在留期間" />

    <p>
      技能実習は年数と試験の合否によって「１号」、「２号」、「３号」に分類されます。在留期間は「技能実習１号」が１年間、「技能実習２号及び３号」は各２年間となっております。試験のタイミングと在留資格ごとの在留期間は以下の表の通りとなります。
    </p>

  

    <img class="pc-img" :src="`${rootUrl}/img/JHI-h_hyou001.png`" alt="在留期間" />
    <img  class="sp-img" :src="`${rootUrl}/img/JHI-h_hyou001sp.png`" alt="在留期間" />

<div class="note">
<p>※講習(座学)：実習実施者(企業単独型のみ)又は監理団体で原則２か月実施(雇用関係なし)</p>
<p>※実習：実習実施者で実施(雇用関係あり)監理団体型；監理団体による訪問指導・監査</p>
</div>
  </div>
</template>

<script>
import UiBar from "@/components/Ui/Bar.vue";
export default {
  name: "GinojisshuseiStay",
  components: {
    UiBar,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>

img{
  width: 100%;
  margin-top: 50px;
}

.note{
  margin-top: 20px;
  margin-bottom: 20px;
}

.pc-img{
  @media screen and (max-width: 767px){
    display: none;
  }
}
.sp-img{
  display: none;
  @media screen and (max-width: 767px){
    display: initial;
  }
}

</style>