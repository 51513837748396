<template>
  <div class="ginojisshusei-acceptance">
    <h1>外国人技能実習生の<br class="sp-kaigyo" />受け入れ</h1>

    <UiLine />

    <img :src="`${rootUrl}/img/JHI-h_006.jpg`" alt="外国人実習生の受け入れ" />

    <div class="foreigner">
      <div class="sentence">
        <p style="margin-bottom: 45px">
          入国管理局への申請や日本語が通じない海外とのやり取りなど、外国人技能実習生を受け入れたいけども煩雑な手続きが面倒と思われている企業様は多くいます。
          当組合では必要な手続きの代行を行ったり、必要書類の書き方をアドバイスさせていただきますので、確実でスムーズな手続きができるよう組合職員が全面的にサポートさせていただきます。
        </p>

        <p>
          まず当組合職員が訪問し、外国人技能実習制度をご説明いたします。
          企業様がはじめて「外国人技能実習生」を受け入れることにあたり、事業内容や受け入れに際しての希望についてヒアリングさせていただきます。
          実習生を受け入れることができる職種・受け入れ可能人数には制限があります。
          メリットやデメリットも含め、些細な疑問にも出来る限りお答えいたしますので、お気軽にお問い合わせください。
        </p>
      </div>

      <img
        class="pic"
        :src="`${rootUrl}/img/JHI-h_009.jpg`"
        alt="外国人実習生の受け入れ図"
      />
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "GinojisshuseiAcceptance",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.ginojisshusei-acceptance {
  img {
    width: 100%;
  }

  .foreigner {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 20px;
    }

    .sentence {
      width: 55%;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      line-height: 2.5em;


      @media screen and (max-width: 767px) {
        width: 100%;
        line-height: 2em;
      }
    }

    img {
      width: 40%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .pic {
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: flex-start;
    }
  }
}
</style>