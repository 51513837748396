<template>
  <div class="ginojisshusei-number">
    <UiBar title="技能実習生の数" />

    <p>
      実習実施者が受け入れることが出来る技能実習生については上限数が定められています。人数枠は以下の表の通りになります。
    </p>
    <div class="row-1">
      <div class="cover">
        <div class="base">
          <p class="title">基本人数枠</p>
          <table>
            <tr>
              <th>実習実施者の常勤の<br class="sp-kaigyo"/>職員の総数</th>
              <th>技能実習生の人数</th>
            </tr>
            <tr>
              <td>301人以上</td>
              <td style="line-height: 1.5em;">常勤職員総数の<br
        class="sp-kaigyo"
      />20分の1</td>
            </tr>
            <tr>
              <td>201人～300人</td>
              <td>15人</td>
            </tr>
            <tr>
              <td>101人~200人</td>
              <td>15人</td>
            </tr>
            <tr>
              <td>101人~200人</td>
              <td>10人</td>
            </tr>
            <tr>
              <td>51人~100人</td>
              <td>6人</td>
            </tr>
            <tr>
              <td class="green">41人~50人</td>
              <td class="green">5人</td>
            </tr>
            <tr>
              <td class="green">31人~40人</td>
              <td class="green">4人</td>
            </tr>
            <tr>
              <td class="green">30人以下</td>
              <td class="green">3人</td>
            </tr>
          </table>
        </div>
        <div class="now">
          <p>技能実習生の受入特例人数枠</p>
          <table>
            <tr>
              <th style="line-height:1.5em;">実習実施機関の常勤<br>の職員の総数</th>
              <th>技能実習生の人数</th>
            </tr>
            <tr>
              <td>301人以上</td>
              <td>常勤職員総数の<br class="sp-kaigyo"/>20分の１</td>
            </tr>
            <tr>
              <td>201人以上 300人以下</td>
              <td>15人</td>
            </tr>
            <tr>
              <td>101人以上 200人以下</td>
              <td>10人</td>
            </tr>
            <tr>
              <td>51人以上 100人以下</td>
              <td>6人</td>
            </tr>
            <tr>
              <td>50人以下</td>
              <td>3人</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="note">
      <table class="base">
       <tr>
         <th>
(注１)
         </th>
         <td>
        技能実習生(1号)の人数が、常勤職員の総数を超えないこと。
         </td>
       </tr>
       <tr>
         <th>
(注２)
         </th>
         <td>
        船上漁業の場合は、技能実習生(１号及び２号)の人数が、各漁船につき乗組員(技能実習生を除く)の人数を超えないこと。

         </td>
       </tr>
      </table>
      </div>
    </div>
    <div class="row-2">
      <p class="title">人数枠(団体監理型)</p>
      <div class="box">
      <table>
        <tr>
          <th colspan="5">人数枠</th>
        </tr>
        <tr>
          <th rowspan="2">第1号(1年間)</th>
          <th rowspan="2">第2号 (2年間)</th>
          <th colspan="3" class="green">優良基準適合者</th>
        </tr>
        <tr>
          <th>第1号(1年間)</th>
          <th>第2号(2年間)</th>
          <th>第3号(2年間)</th>
        </tr>
        <tr>
          基本人数枠
          <td>基本人数枠の２倍</td>
          <td class="green">基本人数枠の２倍</td>
          <td class="green">基本人数枠の4倍</td>
          <td class="green">基本人数枠の4倍</td>
        </tr>
      </table>
    </div>
      <div class="sp">
      <img :src="`${rootUrl}/img/JHI-h_hyou002sp.png`" alt="人数枠(団体監視型)" />
      
      </div>
    </div>


    <div class="row-3">
      <p class="title">常勤職員数(例)</p>

      <table>
        <tr>
          <td class="th">5人の場合</td>
          <td>3人</td>
          <td>6人</td>
          <td>5人</td>
          <td>10人</td>
          <td>15人</td>
        </tr>
        <tr>
          <td class="th">10人の場合</td>
          <td>3人</td>
          <td>6人</td>
          <td>6人</td>
          <td>12人</td>
          <td>18人</td>
        </tr>
        <tr>
          <td class="th">35人の場合</td>
          <td>4人</td>
          <td>8人</td>
          <td>8人</td>
          <td>16人</td>
          <td>24人</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import UiBar from "@/components/Ui/Bar.vue";
export default {
  name: "GinojisshuseiNumber",
  components: {
    UiBar,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  text-align: center;
  margin: 0;
  table-layout: fixed;
  width: 100%;

  tr {
    border: solid 1px;

    th,
    td {
      border: solid 1px;
      vertical-align: middle;
    }

    th {
      background-color: var(--glay2);
      width: 50%;
      padding: 0 25px;
    }
  }
}

.green{
  color: var(--green);
  font-weight: bold;
  border:solid 1px var(--black);
}

.row-1,
.row-2,
.row-3 {
  margin-bottom: 80px;

  .title {
    color: var(--green);
    font-weight: bold;
    margin-bottom: 5px;
  }
  .title:before {
    content: "";
    width: 7px;
    height: 7px;
    display: inline-block;
    border: 7px solid var(--green); //borderを太くしましょう。
    box-sizing: border-box; //親要素を超えないようにしましょう
    margin-right: 5px;

  }
}

.row-1 {
  margin-bottom: 80px;

  p{
    @media screen and (max-width: 767px){
      text-align: left!important;
      line-height: 1.5em;
      margin-top: 10px;
    }
  }

  .cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 767px){
      flex-direction: column;
      align-items: center;
    }

    .base,
    .now {
      width: 48%;

      th{
        padding: 10px;
      }

      @media screen and (max-width: 767px){
        width: 100%;

      th{
        font-size: 13px;
        padding: 5px;
      line-height: 1.5em;
      }

      td{
        vertical-align: middle;
      }
      }
    }
  }
}
.row-2{

  .title{
    @media screen and (max-width: 767px){
      display: none;
    }
  }

.box{
  display: flex;
  justify-content: flex-end;
  table{
    width: 83%;
  }

  @media screen and (max-width: 767px){
    display: none;
  }

  }
.sp{
  display: none;

 @media screen and (max-width: 767px){
   display: initial;

 }

}
}
.row-3 {

@media screen and (max-width: 767px){
      
display: none;
      }
    }

  .th {
    background-color: var(--glay2);
    color: var(--green);
    font-weight: bold;
  }

.note{
 display: flex;
 flex-direction: column;
align-items: flex-end;

.base{
  width: 45%;
  font-size: 13px;
  vertical-align: middle;

  @media screen and (max-width: 767px){
    width: 70%;
  }
tr{
  border: none!important;
th{
    width: 100px;
    padding: 0;
    border: none;background-color: white;
    text-align: right;
    padding-right: 10px;

  
}
td{
border: none;
padding: 0;
line-height: 1.3em;
text-align: left;
}
  }
}
}
</style>