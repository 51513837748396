<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <img
        @click="switchDrawer"
        :src="`${rootUrl}/img/JHI-h_humberger.svg`"
        class="drawer-button"
        alt="メニュー"
      />
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <div class="drawer-header">
          <img
            @click="switchDrawer"
            :src="`${rootUrl}/img/JHI-h_top003.png`"
            class="close-button"
            alt="ドロワーメニュー"
          />
          <div>title</div>
        </div>
        <div @click="switchDrawer" class="drawer-body">
          <router-link to="/">
            <div class="drawer-item">トップページ</div>
          </router-link>
          <router-link to="/ginojisshusei">
            <div class="drawer-item">外国人技能実習生</div>
          </router-link>
          <router-link to="/tokuteiginoshien">
            <div class="drawer-item">特定技能支援</div>
          </router-link>
          <router-link to="/group-purchasing">
            <div class="drawer-item">共同購入</div>
          </router-link>
          <router-link to="/access">
            <div class="drawer-item">団体概要・アクセス</div>
          </router-link>
          <router-link to="/contact-form">
            <div class="drawer-item">お問い合わせ</div>
          </router-link>
        </div>
      </div>
    </div>
    <div
      @click="switchDrawer"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
      // return this.$store.getters.rt;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 200px;
.drawer-wrap {
  .drawer-button {
    width: 50px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 300ms 0s ease;
    position: fixed;
    z-index: 999;
    top: 0;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    width: $width;
    height: 100%; //要らないかも？
    .drawer-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media screen and (max-width: 767px) {
          width: 30px;
        }
      img.close-button {
        width: 15%;
        height: auto;
        cursor: pointer;
        margin-bottom: 10px;

         @media screen and (max-width: 767px){
           width: 80%;
         }

        
      }
    }
    .drawer-body {
      &:last-child {
        border-bottom: 2px solid var(--green);
      }

      .drawer-item {
        text-align: initial;
        color: #333;
        border-top: 2px solid var(--green);
        padding: 20px 0;
        padding-left: 8px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      a {
        text-decoration: none;
        color: var(--balck);
      }

      .drawer-item:hover {
        cursor: pointer;
        color: white;
        background-color: var(--glay2);
      }
    }
  }
  .open-drawer {
    transform: translateX(-$width);
    // right: translateX(-$width);
    right: -$width;
  }
  .close-drawer {
    transform: translateX($width);
    right: -$width;
    // right: 0-$width;
  }
}
.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
.back-gray-show {
  display: initial;
}
</style>