import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


import Home from '../views/Home.vue'
import Ginojisshusei from '../views/Ginojisshusei.vue'
import Tokuteiginoshien from '../views/Tokuteiginoshien.vue'
import GroupPurchasing from '../views/GroupPurchasing.vue'
import Access from '../views/Access.vue'
import CotacntForm from '../views/CotacntForm.vue'
import ContactFormConfirm from '../views/ContactFormConfirm.vue'
import ContactFormComplete from '../views/ContactFormComplete.vue'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'JHI協同組合',
      description: '愛知県安城市にあるJHI協同組合は、「外国人技能実習生の共同受入事業」と「共同購買事業」に力を入れて活動しております。対象国は、フィリピン・ベトナム・タイ・インドネシアとなっています。組合員による共同購入も行っています。'
    }
  },

  {
    path: '/ginojisshusei',
    name: 'ginojisshusei',
    component: Ginojisshusei,
    meta: {
      title: '外国人技能実習生の受け入れ｜フィリピン・ベトナム・タイなど',
      description: '愛知県安城市にあるJHI協同組合は、「外国人技能実習生の共同受入事業」を行っております。対象国は、フィリピン・ベトナム・タイ・インドネシアです。受け入れの流れなど、詳しく説明しています。'
    }
  },

  {
    path: '/tokuteiginoshien',
    name: 'tokuteiginoshien',
    component: Tokuteiginoshien,
    meta: {
      title: '特定技能支援の受け入れ｜フィリピン・ベトナム・タイなど',
      description: '愛知県安城市にあるJHI協同組合は、「特定技能支援の共同受入事業」を行っております。対象国は、フィリピン・ベトナム・タイ・インドネシアです。受け入れの流れなど、詳しく説明しています。'
    }
  },

  {
    path: '/group-purchasing',
    name: 'group-purchasing',
    component: GroupPurchasing,
    meta: {
      title: '組合員による共同購入｜JHI協同組合',
      description: '愛知県安城市にあるJHI協同組合は、組合員による共同購入も行っています。組合員による共同購入によってコストメリットを享受することができます。'
    }
  },

  {
    path: '/access',
    name: 'access',
    component: Access,
    meta: {
      title: '団体概要・アクセス｜JHI協同組合',
      description: '愛知県安城市にあるJHI協同組合の団体概要・アクセスです。'
    }
  },

  {
    path: '/contact-form',
    name: 'contact-form',
    component: CotacntForm,
    meta: {
      title: 'お問い合わせ｜JHI協同組合',
      description: '愛知県安城市にあるJHI協同組合へのお問い合わせはこちらからお願いいたします。'
    }
  },

  {
    path: '/contact-form-confirm',
    name: 'contact-form-confirm',
    component: ContactFormConfirm,
    meta: {
      title: '確認画面｜JHI協同組合',
      description: ''
    }
  },

  {
    path: '/contact-form-complete',
    name: 'contact-form-complete',
    component: ContactFormComplete,
    meta: {
      title: '送信完了｜JHI協同組合',
      description: ''
    }
  },


]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }

})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  // let changeWindowSize = 375;
  // let viewportContent = '';
  // if (window.innerWidth < changeWindowSize) {
  //   viewportContent = 'width=375';
  // }else {
  //   viewportContent = 'width=1300';
  // }
  // document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);

  next();

});

export default router