<template>
  <div id="app">
    <Header class="header" />
    <router-view />
    <ToTopButton />
    <Footer />
  </div>
</template>

<script>
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ToTopButton,
  },
};
</script>
<style lang="scss">
:root {
  --green: rgb(65, 117, 5);
  --black: rgb(62, 58, 57);
  --glay: rgb(153, 153, 153);
  --glay2: rgb(204, 204, 204);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

html,
body {
  margin: 0;
}
body{
  padding-top: 84px;
}

html * {
  box-sizing: border-box;
}
.wrap {
  width: 100vw;//画面幅に対して、基本100%
  max-width: 1150px;//でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;
  @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

h1,
h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.sp-kaigyo {
  display: none;

  @media screen and (max-width: 767px) {
    display: initial;
  }
}

.space-1 {
  padding-top: 197px;

  @media screen and (max-width: 767px) {
    padding-top: 120px;
  }
}

.space-2 {
  padding-top: 163px;

  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
}
</style>
