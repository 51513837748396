<template>
  <div class="home-reasons">
    <h2>JHIがお助けできること<br class="sp-kaigyo">（JHIが選ばれる理由）</h2>
    <UiLine />
    <!-- <HomeReasonsReason  style="margin-bottom: 20px;" :title="`技能実習制度のご案内` " :sentence="`技能実習制度を初めて利用される受け入れ企業様にも丁寧に当組合スタッフがご説明申し上げます。<br>技能実習生の受け入れについては全ての企業様が対象になる訳ではありません。２号対象職種であることや常勤職員数によって受け入れに制限が設けられておりますので、当組合スタッフが説明を含めてご対応いたします。` "/>  -->
    <HomeReasonsReason  style="margin-bottom: 20px;" title="技能実習制度のご案内" sentence="技能実習制度を初めて利用される受け入れ企業様にも丁寧に当組合スタッフがご説明申し上げます。<br>技能実習生の受け入れについては全ての企業様が対象になる訳ではありません。２号対象職種であることや常勤職員数によって受け入れに制限が設けられておりますので、当組合スタッフが説明を含めてご対応いたします。"/> 
    <!-- ↑変数（${}とか）が入らない時はこれでも書けます（「:」と「``」なし）↑ -->
    <HomeReasonsReason style="margin-bottom: 20px;" :title="`共同購入によるコスト削減` " :sentence="`組合員様が購入される資材や備品などのコスト削減を実現させるため、様々な商材取り扱いに挑戦しております。
当組合独自の商材ルートをご利用いただき共同購入していただくことで、組合員様のコスト低減に活用ください。` "/>

    <HomeReasonsReason :title="`組合員同士で<br>情報共有ができる` " :sentence="`多様な職種の組合員様によって当組合は形成されております。職種が異なれば組合員様の悩みもまた多様となりますので、アイデアなどを情報共有させていただくことで、悩みの解決になるよう努めさせていただきます。` "/>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
import HomeReasonsReason from "@/components/Home/Reasons/Reason.vue";

export default {
  name: "HomeReasons",
  components: {
    UiLine,
    HomeReasonsReason,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
h2{
  @media screen and (max-width: 767px) {
    line-height: 1.5em;
  }
}
/deep/br{
  @media screen and (min-width: 767px) and(max-width: 1215px) {
    display: none;
  }
}

</style>