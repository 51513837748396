<template>
  <div class="home-reason">
    <div class="item">
      <div class="wrap">
        <div class="box">
          <div class="title">
            <p v-html="title"></p>
          </div>
          <div class="description">
            <p v-html="sentence"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeResonsReson",
  components: {},
  props: {
    title: String,
    sentence: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  background-color: rgb(220, 220, 220);

  @media screen and (max-width: 767px) {
    background-color: white;
  }

  .wrap {
    .box {
      display: flex;
      height: 150px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        height: auto;
      }

      @media screen and (min-width: 767px) and(max-width: 1215px) {
        height: 180px;
      }

      .title {
        background-color: rgb(65, 117, 5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        padding: 30px;
        text-align: center;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        p {
          color: white;
          font-size: 21px;
          font-weight: bold;
        }
      }

      .description {
        padding: 20px 0;
        display: flex;
        align-items: center;
        width: 70%;

        @media screen and (max-width: 767px) {
          background-color: rgb(220, 220, 220);
          width: 100%;
          height: auto;
          padding: 15px;
        }

        p {
          margin-left: 30px;
          font-size: 15px;

          @media screen and (max-width: 767px) {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>