<template>
  <div class="header">
    <div class="top">
      <div class="logo">
        <div class="to-top" @click="top">
          <p>JHI協同組合</p>
        </div>
      </div>
      <div class="pc">
        <div class="tel-hours">
          <span>お気軽にお問い合わせください</span>
          <div class="cover">
            <p class="tel">0566-95-3210</p>
            <p class="hour">（平日：9時～17時）</p>
          </div>
        </div>
        <router-link to="/contact-form#contact" class="mail">
          <img :src="`${rootUrl}/img/JHI-h_mail.svg`" alt="mail" />
          <span>お問い合わせ</span>
        </router-link>
        <div class="hamburger">
          <Drawer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "@/components/Ui/Drawer.vue";
export default {
  name: "Header",
  components: {
    Drawer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background-color: rgb(65, 117, 5);
  height: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  .logo {
    // width: 20%;
    width: 190px;

    @media screen and (max-width: 767px) {
      width: 80%;
    }
    .to-top {
      cursor: pointer;

      p {
        // width: 66%;
        width: 100%;
        margin: 0 15px;
        color: white;
        font-size: 32px;
        font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体",
          "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
        font-weight: bold;
      }
    }
    .to-top:hover{
      opacity: 0.8;
    } 
  }
  .pc {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 550px;

    @media screen and (max-width: 767px) {
      width: 25%;
    }

    .tel-hours {
      font-size: 16px;
      width: 65%;

      @media screen and (max-width: 767px) {
        display: none;
      }

      .cover {
        display: flex;
        align-items: center;
        .tel {
          font-size: 25px;
        }
        .hour {
          font-size: 17px;
        }
      }
    }

    .mail {
      height: 45px;
      display: flex;
      align-items: center;

      width: 30%;
      text-decoration: none;
      color: white;
      border: solid 1px white;
      transition: 0.4s;

      &:hover {
        background-color: var(--glay);
      }

      @media screen and (max-width: 767px) {
        display: none;
      }

      img {
        width: 15%;
        margin-left: 10px;
        margin-right: 10px;
      }

      span {
        padding: 0 5px 0 0;
      }
    }

    a {
      text-decoration: none;
      color: white;
    }

    .hamburger {
      margin: 0 15px;

      @media screen and (max-width: 767px) {
        margin: 0 10px;
      }

      img {
        width: 38%;

        @media screen and (max-width: 767px) {
          width: 15%;
        }
      }
    }
  }
}
</style>