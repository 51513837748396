<template>
  <div class="cotacnt-form-tel">
    <div class="number">
      <div class="box">
        <p>
          <a href="tel:0566953210">0566-95-3210</a><br class="sp-kaigyo" /><span
            >（平日：9時～17時まで）</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CotacntFormTel",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.number {
  display: flex;
  justify-content: center;
  color: var(--green);

  .box {
    p {
      font-size: 70px;

      @media screen and (max-width: 767px) {
        font-size: 32px;
        font-weight: bold;
      }

      @media screen and (min-width: 768px) and(max-width: 915px) {
          line-height: 1em;
        .sp-kaigyo{
          display: initial;
        }
      }
      a {
        text-decoration: none;
        color: var(--green);
        pointer-events: none;
        cursor: default;
        @media screen and (max-width: 767px) {
          pointer-events: initial;
          cursor: pointer;
        }
      }
    }
    span {
      font-size: 37px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
</style>