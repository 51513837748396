<template>
  <div class="tokuteiginoshien-requirements">
    <UiBar title="特定技能要件" />

    <div class="pc">
      <div class="box">
        <table>
          <tr>
            <th>目的</th>
            <th>在留期間</th>
            <th>転職・転籍</th>
            <th>人数枠</th>
          </tr>
          <tr>
            <td><span>日本の人手不足解消</span></td>
            <td>
              <p class="row-1">特定技能1号:通算5年</p>
              <p class="row-2">特定技能2号:無期限 (建築・造船のみ)</p>
            </td>
            <td>可能</td>
            <td>
              <p class="row-1">人数制限なし</p>
              <p class="row-2">(建設と介護は常勤職員数を超えてはいけない)</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="sp">
      <div class="box">
        <table>
          <tr>
            <th>目的</th>
          </tr>
          <tr>
            <td><span>日本の人手不足解消</span></td>
          </tr>
          <tr>
            <th>在留期間</th>
            </tr><tr>
            <td>
              <p class="row-1">特定技能1号:通算5年</p>
              <p class="row-2">特定技能2号:無期限 (建築・造船のみ)</p>
            </td>
            </tr>
            <tr></tr>
            <th>転職・転籍</th>
            <tr>
            <td>可能</td>
            </tr>
            <tr>
            <th>人数枠</th>
            </tr><tr>
            <td>
              <p class="row-1">人数制限なし</p>
              <p class="row-2">(建設と介護は常勤職員数を超えてはいけない)</p>
            </td>
            </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import UiBar from "@/components/Ui/Bar.vue";

export default {
  name: "TokuteiginoshienAcceptance",
  components: {
    UiBar,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

      th,
      td {
        border: solid 1px var(--black);
        vertical-align: middle;
        line-height: 1.5em;
      }

      th {
        background-color: var(--glay2);
        padding: 10px;
      }

      td {
        color: var(--green);
        font-weight: bold;       
        padding: 20px 0;
      }
.pc {
  @media screen and (max-width: 767px) {
    display: none;
  }

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      text-align: center;

      td{


        .row-2 {
          font-size: 12px;
          margin-top: 0;
          color: var(--black);
          height: 25px;

        }
      }


      }
    }
  }


.sp{
  display: none;

  @media screen and (max-width: 767px){
    display: initial;
    
    table{
     table-layout: fixed;
      border-collapse: collapse;
      text-align: center;
      width: 100%;

      td{
         .row-1 {
          padding-bottom: 0;
        }

        .row-2 {
          font-size: 11px;
          margin-top: 0;
          color: var(--black);
          height: 25px;
        }
      }
    }

   }
}
</style>