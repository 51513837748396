<template>
  <div class="ginojisshusei-flows-flow">
    <div class="step">
      <div class="green">
        <p>STEP<br class="sp-kaigyo"/><span>{{ step }}</span></p>
      </div>
      <div class="gray">
        <p v-html="title">
        </p>
      </div>
    </div>

    <p style="margin: 20px 0;" v-html="descripton"></p>

  </div>
</template>

<script>
// import UiBar from "@/components/Ui/Bar.vue";
export default {
  name: "GinojisshuseiFlowsFlow",
  components: {
    // UiBar,
  },
  props: {
    step: Number,
    title: String,
    descripton: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  font-weight: bold;
  width: 100%;

  
  
  .green,.gray{
    padding: 15px 0;

  }
  .green {
    width: 18%;
    background-color: var(--green);
    color: white;
    text-align: center;

    p{
      font-size: 20px;

      @media screen and (max-width: 767px){
        font-size: 16px;

        span{
          font-size: 24px;
        }
      }
    }
  }

  .gray {
    width: 82%;
    background-color: rgb(223,223,223);
    color: var(--green);
    display: flex;
    align-items: center;

    p{
      padding-left: 20px;
      font-size:  21px;

     
    }
  }



}
</style>