<template>
  <div class="tokuteiginoshien-plans">

    <UiBar title="支援計画" />

    <div class="shien-plan">

   <TokuteiginoshienPlansPlan class="width" :number="1" title="事前ガイダンス" :imgpath="`JHI-h_013.jpg`" sentence="雇用契約終結後、在留資格認定証明書交付申請前又は在留資格変更許可申請前に、労働条件・活動内容・入国手続・保証金徴収の有無等について、対面・テレビ電話等で説明"/>
   <TokuteiginoshienPlansPlan class="width" :number="2" title="出入国する際の送迎" :imgpath="`JHI-h_014.jpg`" sentence="<ul>
     <li>入国時に空港等と事業所又は住居への送迎</li><li>帰国時に空港の保安検査場までの送迎・同行</li></ul>"/>
   
   <TokuteiginoshienPlansPlan class="width" :number="3" title="住居確保・生活に必要な契約支援" :imgpath="`JHI-h_015.jpg`" sentence="<ul>
     <li>連帯保証人になる</li>
     <li>社宅を提供する等</li>
     <li>銀行口座等の開設</li>
     <li>携帯電話やライフラインの契約等を案内</li>
     <li>各手続の補助</li>
   </ul>"/>
   <TokuteiginoshienPlansPlan class="width" :number="4" title="生活オリエンテーション" :imgpath="`JHI-h_016.jpg`" sentence="円滑に社会生活をを営めるよう日本のルールやマナー、公共機関の利用方法や連絡先、災害時の対応等の説明"/>
   <TokuteiginoshienPlansPlan class="width" :number="5" title="公的手続等への同行" :imgpath="`JHI-h_017.jpg`" sentence="必要に応じて住居地・社会保証・税などの手続の同行、書類作成の補助"/>
   <TokuteiginoshienPlansPlan class="width" :number="6" title="日本語学習の機会の提供" :imgpath="`JHI-h_018.jpg`" sentence="日本語教室等の入学案内、日本語学習教材の情報提供等"/>
   <TokuteiginoshienPlansPlan class="width" :number="7" title="相談・苦情への対応" :imgpath="`JHI-h_019.jpg`" sentence="職場や生活上の相談・苦情等について、外国人が十分に理解することができる言語での対応、内容に応じた必要な助言、指導等"/>
   <TokuteiginoshienPlansPlan class="width" :number="8" title="日本人との交流促進" :imgpath="`JHI-h_020.jpg`" sentence="自治会等の地域住民との交流の場や、地域のお祭りなどの行事の案内や、参加の補助等"/>
   <TokuteiginoshienPlansPlan class="width" :number="9" title="転職支援(人員整理等の場合)" :imgpath="`JHI-h_021.jpg`" sentence="受入れ側の都合により雇用契約を解除する場合の転職先を探す手伝いや、推薦状の作成等に加え、求職活動を行うための有給休暇の付与や必要な行政手続の情報の提供"/>
   <TokuteiginoshienPlansPlan class="width" :number="10" title="定期的な面談・行政機関への通報" :imgpath="`JHI-h_022.jpg`" sentence="支援責任者等が外国人及びその上司等と定期的(3か月に1回以上)に面談し、労働基準法違反等あれば通報"/>
    </div>
  <div class="right">
  <p>法務省：新たな外国人材の受入れ及び共生社会実現に向けた取り組みより</p>
  </div>
  </div>
</template>

<script>
import UiBar from "@/components/Ui/Bar.vue";
import TokuteiginoshienPlansPlan from "@/components/Tokuteiginoshien/Plans/Plan.vue";

export default {
  name: "TokuteiginoshienPlans",
  components: {
    UiBar,
    TokuteiginoshienPlansPlan,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.width{
  width: 48%;

   @media screen and (max-width: 767px){
     width: 100%;
   }
}

.shien-plan{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

   @media screen and (max-width: 767px){
     flex-direction: column;
     width: 100%!important;
   }

}
.right{
text-align: end;

@media screen and (max-width: 767px){
    font-size: 13px;
    text-align: start;
    margin-top: 15px; 
   }
}
</style>