<template>
  <div class="tokuteiginoshien">

    <div class="wrap">
      <TokuteiginoshienAcceptance class="space-1" />
    </div>

    <div class="wrap">
      <TokuteiginoshienPlans class="space-3" />
    </div>

    <div class="wrap">
      <TokuteiginoshienRequirements style="padding-top: 30px;" />
    </div>

    <div class="wrap">
      <TokuteiginoshienIndustry style="padding-top: 30px;" />
    </div>

    <div class="wrap">
      <TokuteiginoshienFlows class="space-4" />
    </div>


    
  </div>
</template>

<script>
import TokuteiginoshienAcceptance from "@/components/Tokuteiginoshien/Acceptance.vue";
import TokuteiginoshienPlans from "@/components/Tokuteiginoshien/Plans.vue";
import TokuteiginoshienRequirements from "@/components/Tokuteiginoshien/Requirements.vue";
import TokuteiginoshienIndustry from "@/components/Tokuteiginoshien/Industry.vue";
import TokuteiginoshienFlows from "@/components/Tokuteiginoshien/Flows.vue";



export default {
  name: "Tokuteiginoshien",
  components: {
    TokuteiginoshienAcceptance,
    TokuteiginoshienPlans,
    TokuteiginoshienRequirements,
    TokuteiginoshienIndustry,
    TokuteiginoshienFlows,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>

.space-3{
  padding-top: 60px;

  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
}
.space-4{
  padding-top: 169px;

  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}

</style>