import { render, staticRenderFns } from "./CotacntForm.vue?vue&type=template&id=dfa0adac&scoped=true"
import script from "./CotacntForm.vue?vue&type=script&lang=js"
export * from "./CotacntForm.vue?vue&type=script&lang=js"
import style0 from "./CotacntForm.vue?vue&type=style&index=0&id=dfa0adac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa0adac",
  null
  
)

export default component.exports