<template>
  <div class="cotacnt-form-contact">
   <h2>お問い合わせ</h2>

   <UiLine />
   


</div>
</template>

<script>
import UiLine from '@/components/Ui/Line.vue';

export default {
  name: "CotacntFormContact",
  components: {
    UiLine,
    },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bar{
  width: 100%;
}



</style>