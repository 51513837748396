<template>
  <div class="groupPurchasing">

    <div class="wrap">
      <GroupPurchasingPurchase class="space-1" />
    </div>

   


    
  </div>
</template>

<script>
import GroupPurchasingPurchase from "@/components/GroupPurchasing/Purchase.vue";


export default {
  name: "GroupPurchasing",
  components: {
    GroupPurchasingPurchase,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  width: 100%;
}

</style>