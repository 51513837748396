<template>
  <div class="ginojisshusei-quetions-quetion">
    <div class="box">
    <div class="question">
      <div class="q">Q</div>
      <div class="sentence">
       <p> {{ sentence }} </p>
      </div>
    </div>

    <div class="answer">
      <div class="a">A</div>
      <div class="description">
        <p>{{ sentence2 }}</p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GinojisshuseiQuestionsQuestion",
  components: {},
  props: {
    sentence: String,
    sentence2: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.box{
  margin-bottom: 30px;

.q,.a{
  font-size: 35px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question,.answer{
    display: flex;

}

.question{
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 0;
  .q{
    width: 5%;
    background-color: rgb(213, 222, 154);
    color: var(--green);
    padding: 13px 0;

  @media screen and (max-width: 767px){
    width: 16%;
  }
  }
  .sentence{
    width: 95%;
    background-color: var(--green);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 21px;
    p{
      padding-left: 20px;


    }

  }
}

.answer{
  width: 100%;
  .a{
    width: 5%;
    background-color: var(--green);
    height: 56px;

    @media screen and (max-width: 767px){
    width: 16%;
  }
  }

  .description{
    width: 95%;

    p{
      padding-left: 18px;
    }
    

  }


}
}
</style>