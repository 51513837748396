<template>
  <div class="Tokuteiginoshien-plans-plan">
    <div class="plan">
      <div class="bar">
        <div class="number">
          {{ number }}
        </div>
        <div class="title">
          {{ title }}
        </div>
      </div>

      <div class="description">
        <div class="img">
          <img :src="`${rootUrl}/img/${imgpath}`" alt="支援計画" />
        </div>
        <!-- <div class="sentence">
          {{ sentence }}
        </div> -->
        <div class="sentence" v-html="sentence"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokuteiginoshienPlansPlan",
  components: {},
  props: {
    number: Number,
    title: String,
    imgpath: String,
    sentence: String,
    // sentence2: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.plan {
  margin-top: 30px;
       @media screen and (max-width: 767px){
         width: 100%;
       }

  .bar {
    width: 100%;
    display: flex;


    .number,
    .title {
      padding: 15px;
      font-weight: bold;

    }

    .number {
      width: 10%;
      background-color: var(--green);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 33px;
       
       @media screen and (max-width: 767px){
         width: 20%;
       }
    }
    .title {
      width: 90%;
      background-color: var(--glay2);
      font-size: 21px;
      color: var(--green);
       @media screen and (max-width: 767px){
         width: 80%;
       }
    }
  }

  .description{
    display: flex;
    margin-top: 20px;

     @media screen and (max-width: 767px){
       flex-direction: column;
     }

    .img{
      width: 40%;

       @media screen and (max-width: 767px){
         width: 100%;
         text-align: center;
       }
      img{
        width: 90%;
      }
    }

    .sentence{
      width: 60%;

       @media screen and (max-width: 767px){
         width: 100%;
       }
    }
  }
}
/deep/ul{
  li {
    list-style-type: none; /*点を非表示*/
    position: relative; /*基準位置*/
    padding-left: 0.6em;
  }

  li:before{
    border-radius: 50%; /*丸くする*/
    width: 10px; /*点の幅*/
    height: 10px; /*点の高さ*/
    display: block;
    position: absolute; /*絶対配置*/
    left: -8px; /*点の位置*/
    top: 0.6em; /*点の位置*/
    content: "";
    background: var(--green); /*点の色*/

    @media screen and (max-width: 767px){
    left: 0; 
    
   }
  }

   
}
</style>