<template>
  <div class="Tokuteiginoshien-flows">
 <h2>当登録支援機関の<br class="sp-kaigyo">受け入れの流れ</h2>

<UiLine />

   <UiFlow  :step="1" title="特定技能雇用受入<br class='sp-kaigyo'>お問合せ" :descripton="`ホームページやご紹介等で特定技能の受入をご検討中、特定技能の受入について詳しく知りたいなどの場合には、お問い合わせ下さい。特定技能の受入に係る説明をさせていただきます。その際は貴社のご希望の条件等などにつきましてもヒアリングさせて下さい。`"/> 
  
   <UiFlow  :step="2" title="<span class='sp-size'>特定技能で雇用できる国内外にいる外国人のご紹介" :descripton="`<p class='bold'>【海外にいる特定技能の条件を満たした外国人】</p>
   企業様が希望する条件の人材を探します。求人対象者としては、日本に技能実習生として来日したことのある人、日本語試験および技能試験に合格した人。
   <p class='bold' style='margin-top: 20px;'>【国内にいる特定技能の条件を満たした外国人】</p>技能実習生２号を良好に終了した人。<br>留学生などで卒業が決まっており、日本語試験と技能試験を合格した人。`"/> 
   <UiFlow  :step="3" title="面接・選考" :descripton="`企業様自身で直接特定技能候補者と面接し人材を確定していただきます。確定すれば、特定技能雇用契約を締結していただきます。`"/> 
   <UiFlow  :step="4" title="<span class='sp-size'>特定技能１号外国人支援計画申請と在留資格認定申請</span>" :descripton="`当組合で作成の支援を行いますのでご安心ください。在留資格認定証明書が交付されましたら、査証「ビザ」の申請や取得パスポート等も用意して、これらの書類を入国時に提示して日本への入国となります。`"/> 
   <UiFlow  :step="5" title="入国" :descripton="`空港までの送迎は当組合で実施します。当組合が責任をもって送迎いたします。`"/> 
   <UiFlow  :step="6" title="配属、雇用開始" :descripton="`定期的な面談を実施し、企業様および特定技能雇用者の不安や不満はないか確認します。`"/> 

   <div class="img"><img
          :src="`${rootUrl}/img/JHI-h_023.jpg`"
          alt="人材不足解消"
        />
    </div>
    </div>

</template>

<script>
import UiFlow from "@/components/Ui/Flow.vue";
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "Tokuteiginoshien-Flows",
  components: {
    UiFlow,
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>

.img{
  display: flex;
justify-content: center;
align-items: flex-start;
margin-bottom: 80px;
margin-top: 50px;
  img{
  width: 50%;
@media screen and (max-width: 767px){
           width: 100%; 
           height: auto;
          }


  }

}

/deep/.sp-size{  
  @media screen and (max-width: 767px){
  
  font-size: 17px;
  }
}

/deep/.bold{
  color: var(--green);
  font-weight: bold;
  font-size: 18px;

  }


</style>