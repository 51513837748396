<template>
  <div class="Ui-DetailButton">
      
    <div class="cover">
      <router-link :to="`${path}`">
    <div class="button">
      <p>{{link}}</p>
      <div class="arrow"></div>
    </div>
    </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiDetailButton",
  components: {},
  props: {
    path: String,
    link: String,
  },
  data() {
    return {
      // a:1,
      // b:2,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
    // sum() {
    //   return this.a+this.b;
    // },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cover{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

.button{
  display: flex;
  align-items: center;


  p{
    font-weight: bold;
  }
  }

.arrow{
  position: relative;
  display: inline-block;
  padding-left: 20px;

}

.arrow::before{
  content: '';
  width: 15px;
  height: 15px;
  border: 0px;
  border-top: solid 4px var(--green);
  border-right: solid 4px var(--green);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -9px;
}
}

a{
  text-decoration: none;
  color: var(---black);

}
</style>