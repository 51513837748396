<template>
  <div class="ginojisshusei">

    <div class="wrap">
      <GinojisshuseiAcceptance class="space-1" />
    </div>

    <div class="wrap">
      <GinojisshuseiNumber style="padding-top: 50px;" />
    </div>

    <div class="wrap">
      <GinojisshuseiWork style="padding-top: 0px;" />
    </div>

    <div class="wrap">
      <GinojisshuseiStay style="padding-top: 0px;" />
    </div>
    
    <div class="wrap">
      <GinojisshuseiAbout class="space-1" />
    </div>

    <div class="wrap">
      <GinojisshuseiFlows class="space-2" />
    </div>

    <div class="wrap">
      <GinojisshuseiQuestions class="space-3" />
    </div>


    
  </div>
</template>

<script>
import GinojisshuseiAcceptance from "@/components/Ginojisshusei/Acceptance.vue";
import GinojisshuseiNumber from "@/components/Ginojisshusei/Number.vue";
import GinojisshuseiWork from "@/components/Ginojisshusei/Work.vue";
import GinojisshuseiStay from "@/components/Ginojisshusei/Stay.vue";
import GinojisshuseiAbout from "@/components/Ginojisshusei/About.vue";
import GinojisshuseiFlows from "@/components/Ginojisshusei/Flows.vue";
import GinojisshuseiQuestions from "@/components/Ginojisshusei/Questions.vue";


export default {
  name: "Ginojisshusei",
  components: {
    GinojisshuseiAcceptance,
    GinojisshuseiNumber,
    GinojisshuseiWork,
    GinojisshuseiStay,
    GinojisshuseiAbout,
    GinojisshuseiFlows,
    GinojisshuseiQuestions


  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  width: 100%;
}

.space-1{
  padding-top: 197px;

        @media screen and (max-width: 767px) {
      padding-top: 70px;
      }
}

.space-2{
  @media screen and (max-width: 767px) {
      padding-top: 70px;
      }
}

.space-3{
  padding-top: 163px;
  
  @media screen and (max-width: 767px) {
      padding-top: 70px;
      }

}
</style>