<template>
  <div class="confirm">


    <!-- <UiBar title="メールでのお問い合わせ" style="padding-top: 110px;"/> -->
    
    <div class="wrap">
      <CotacntFormMail :type="1" style="padding-top: 110px;" />
    </div>
    
  </div>
</template>

<script>
// import UiBar from '@/components/Ui/Bar.vue';
import  CotacntFormMail from '@/components/CotacntForm/Mail.vue';


export default {
  name: "ContactFormConfirm",
  components: {
    // UiBar,
    CotacntFormMail,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },

};
</script>
<style lang="scss" scoped>




</style>