<template>
  <div class="footer">
    <p class="sentence">
      まずはお電話またはお問い合わせフォームからのご相談のご予約をお願いいたします。
    </p>
    <div class="footer-line">
      <div class="contact-tel">
        <div class="tel-hours">
          <span>お電話でのお問い合わせ</span>
          <div class="cover">
            <p class="tel"><a href="tel:0566953210">0566-95-3210</a></p>
            <p class="hour">（平日：9時～17時）</p>
          </div>
        </div>
      </div>
      <div class="contact-mail">
        <router-link to="/contact-form#contact">
          <div class="tel-hours">
            <span>メールでのお問い合わせ</span>
            <div class="cover">
              <p class="mail">お問い合わせフォーム</p>
              <p class="hour">（24時間受付）</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="copy-right">
      <img :src="`${rootUrl}/img/JHI-h_logo002.svg`" alt="JHI協同組合ロゴ" />
      <p>Copyright(C)JHI協同組合&emsp;&emsp;All Rights Reserved</p>
    </div>
  </div>
</template>



<script>
export default {
  name: "Footer",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 100px;

  .sentence {
    text-align: center;
    @media screen and (max-width: 1034px) {
      padding: 0 20px;
      line-height: 1.5em;
      font-size: 14px;
    }
  }

  .footer-line {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    margin: 15px 0 10px 0;

    @media screen and (max-width: 1034px) {
      flex-direction: column;
    }

    .tel-hours {
      font-size: 20px;
      text-align: center;
      padding: 20px 35px;

      .cover {
        margin-top: 8px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1034px) {
          flex-direction: column;
        }

        .tel {
          font-size: 30px;

          @media screen and (max-width:1034px) {
            margin-bottom: 10px;
          }
        }
        .mail{
          font-size: 28px;

          @media screen and (max-width: 1034px) {
            margin-bottom: 10px;
          }
        }
        .hour {
          font-size: 22px;
        }
      }
    }
    .contact-tel,
    .contact-mail {
      width: 50%;
      display: flex;
      text-align: center;
      height: 95px;

      @media screen and (max-width: 1034px) {
        width: 100%;
        height: 125px;
      }
    }

    .contact-tel {
      background-color: var(--green);
      justify-content: flex-end;

      @media screen and (max-width: 1034px) {
        justify-content: space-evenly;

      }

      a{
        text-decoration: none;
        color: white;
        pointer-events: none;
        cursor: default;

         @media screen and (max-width: 1034px) {
          pointer-events: initial;
          cursor: pointer;
        }
      }
    }

    .contact-mail {
      background-color: var(--glay);
      justify-content: flex-start;

        a {
        display: block;
        height: 100%;
        text-decoration: none;
        color: white;

        :hover{
          color: var(--green);
        }
      }
    

      @media screen and (max-width: 1034px) {
        justify-content: space-evenly;

        .mail {
          font-size: 25px !important;
          font-weight: bold;
        }
      }
    }
  }

  .copy-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    height: 20px;

    @media screen and (max-width: 1034px) {
      font-size: 14px;
      justify-content: space-evenly;
      padding: 0 10px;

      p {
        height: 20px;
      }
    }

    img {
      width: 180px;
      margin-right: 17px;

      @media screen and (max-width: 1034px) {
        display: none;
      }
    }
  }
}
</style>