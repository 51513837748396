<template>
  <div class="cotacnt-form-mail">
    <div class="cover">
      <div class="title">
        <div v-if="type === 0">
          <h3>
            メールでのお問い合わせは、お問い合わせフォームからお願いいたします。
            <span>※24時間受付</span>
          </h3>
        </div>
        <div v-else-if="type === 1">
          <h3>
            入力内容にお間違いがないかご確認の上、「送信」ボタンを押して下さい。
          </h3>
        </div>
      </div>
      <div class="form">
        <table>
          <tr>
            <th>企業名<span>(※必須)</span></th>
            <td>
              <div v-if="type === 0">
                <input v-model="form.companyName" type="text" class="row1" />
              </div>
              <div v-else-if="type === 1">{{ form.companyName }}</div>
            </td>
          </tr>
          <tr>
            <th>お名前<span>(※必須)</span></th>
            <td>
              <div v-if="type === 0">
                <input v-model="form.name" type="text" class="row1" />
              </div>
              <div v-else-if="type === 1">{{ form.name }}</div>
            </td>
          </tr>
          <tr>
            <th>電話番号<span>(※必須)</span></th>
            <td>
              <div v-if="type === 0">
                <input v-model="form.tel" type="tel" class="row1" />
              </div>
              <div v-else-if="type === 1">{{ form.tel }}</div>
            </td>
          </tr>
          <tr>
            <th>メールアドレス<span>(※必須)</span></th>
            <td>
              <div v-if="type === 0">
                <input v-model="form.mail" type="email" class="row1" />
              </div>
              <div v-else-if="type === 1">{{ form.mail }}</div>
            </td>
          </tr>
          <tr>
            <th>お問い合わせ種別<span>(※必須)</span></th>
            <td>
              <div v-if="type === 0">
                <label class="sp"
                  ><input
                    v-model="form.type"
                    type="radio"
                    :value="0"
                  />外国人技能実習生</label
                >
                <label class="sp"
                  ><input
                    v-model="form.type"
                    type="radio"
                    :value="1"
                  />特定技能支援</label
                >
                <label class="sp"
                  ><input
                    v-model="form.type"
                    type="radio"
                    :value="2"
                  />共同購入</label
                >
                <label class="sp"
                  ><input
                    v-model="form.type"
                    type="radio"
                    :value="9"
                  />その他</label
                >
              </div>
              <div v-else-if="type === 1">
                <span v-show="form.type === 0">外国人技能実習生</span>
                <span v-show="form.type === 1">特定技能支援</span>
                <span v-show="form.type === 2">共同購入</span>
                <span v-show="form.type === 9">その他</span>
              </div>
            </td>
          </tr>
          <tr>
            <th class="des">
              <div class="last-row">お問い合わせ内容<span>(※必須)</span></div>
            </th>
            <td>
              <textarea
                v-if="type === 0"
                v-model="form.description"
                placeholder="こちらにお問い合わせ内容を入力してください。"
              ></textarea>
              <div v-else-if="type === 1">{{ form.description }}</div>
            </td>
          </tr>
        </table>
      </div>

      <div @click="confirm" class="button" v-if="type === 0">
        <div><p>確認する</p></div>
      </div>
      <div class="soushin" v-if="type === 1">
        <div @click="back" class="button"><p>修正する</p></div>
        <div @click="send" class="button"><p>送信</p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CotacntFormMail",
  components: {},
  props: {
    type: Number,
  },
  data() {
    return {
      form: {
        companyName: "",
        name: "",
        tel: "",
        mail: "",
        type: 0,
        description: "",
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  created() {
    if (sessionStorage.getItem("companyName") !== null) {
      this.form.companyName = sessionStorage.getItem("companyName");
      this.form.name = sessionStorage.getItem("name");
      this.form.tel = sessionStorage.getItem("tel");
      this.form.mail = sessionStorage.getItem("mail");
      this.form.type = parseInt(sessionStorage.getItem("type"));
      this.form.description = sessionStorage.getItem("description");
    }
  },
  methods: {
    confirm() {
      // alert(this.form.companyName);
      // console.log(`company name: ${this.form.companyName}`);
      // console.log(`company name: ${this.form}`);//※ダメなやつ
      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      //企業名チェック
      if (this.form.companyName !== "") {
        console.log("企業名 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「企業名」";
      }

      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        console.log("電話番号 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf( 'hotmail.co.jp' );
        var outResult = mailAddress.indexOf( 'outlook.jp' );

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      //企業名チェック
      if (this.form.type !== "") {
        console.log("種別 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ種別」";
      }
      //企業名チェック
      if (this.form.description !== "") {
        console.log("お問い合わせ内容 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ内容」";
      }

      //結果発表
      if (validation_ok === true) {
        // alert("OK!");
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }

      //バリデーション
      if (
        this.form.companyName !== "" &&
        this.form.name !== "" &&
        this.form.tel !== "" &&
        this.form.mail !== "" &&
        this.form.description !== ""
      ) {
        //エラー処理
        // alert("合格");
      } else {
        //合格
        alert("入力に誤りがあります");
        return;
      }
      // if (
      //   this.form.companyName==="" ||
      //   this.form.name==="" ||
      //   this.form.tel==="" ||
      //   this.form.mail==="" ||
      //   this.form.description===""
      // ) {
      //   //エラー処理
      //   alert("入力に誤りがあります");
      //   return;
      // }else{
      //   //合格
      //   alert("合格");
      // }

      //セッションストレージの保存

      sessionStorage.setItem("companyName", this.form.companyName);
      sessionStorage.setItem("name", this.form.name);
      sessionStorage.setItem("tel", this.form.tel);
      sessionStorage.setItem("mail", this.form.mail);
      sessionStorage.setItem("type", this.form.type);
      sessionStorage.setItem("description", this.form.description);

      //遷移する
      this.$router.push("/contact-form-confirm");
    },
    send() {
      // let test = 'テスト';
      // // alert(test);
      // alert(this.form.companyName);
      // alert(test);
      let params = new URLSearchParams();
      params.append("company_name", this.form.companyName);
      params.append("name", this.form.name);
      params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("type", this.form.type);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.result === 1) {
            sessionStorage.clear();
            this.$router.push("/contact-form-complete");
          } else {
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}

.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  .title {
    padding-bottom: 30px;
    padding-left: 0;
  }

  .form {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    table {
      table-layout: fixed;
      width: 100%;
      th,
      td {
        border: solid 1px var(--black);
      }

      th {
        width: 30%;
        color: var(--green);
        text-align: left;
        padding: 20px;
        border-left: none;
        vertical-align: middle;

        .des {
          padding: 0 0 0 20px;

          @media screen and (max-width: 767px) {
            padding: 0;
          }

          .last-row {
            display: flex;
            align-items: center;
          }

          span {
            font-size: 12px;
          }
        }
      }
      td {
        border-right: none;
        padding-left: 20px;
        vertical-align: middle;

        .div {
          word-break: break-all;
        }
      }

      @media screen and (max-width: 767px) {
        tr,
        th,
        td {
          display: block;
          width: auto;
        }

        th {
          padding-bottom: 0r;
          border: none;
          width: 100%;
          font-weight: bold;
          padding: 20px 0 0 0;
        }

        td {
          border: none;
          width: 100%;
          padding-left: 0;

          .last-row {
            padding-left: 0;
          }
        }

        .input,
        .textarea {
          width: 100%;
          margin-bottom: 10px;
          vertical-align: middle;

          @media screen and (max-width: 767px){
            border: solid 1px;
          }


        }
      }
    }
  }
  .button {
    border: solid 1px var(--black);
    padding: 5px 35px;
    width: 150px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: 0.4s;

    @media screen and (max-width: 767px) {
      width: 50%;
      padding: 5px 10px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      background-color: var(--green);
      color: white;
    }
  }
  .row1,
  textarea {
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    border: solid 0.5px var(--glay2);
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    column-count: initial !important;
    word-break: break-all;
  }

  .soushin {
    display: flex;
    justify-content: space-between;
    .button {
      width: 150px;
      @media screen and (max-width: 767px) {
      }
      width: 40%;
    }
  }

  textarea {
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-rendering: auto;
  }

  .sp {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}
</style>