<template>
  <div class="ginojisshusei-Quetions">
   <h2>よくある質問</h2>

      <UiLine />

      <GinojisshuseiQuestionsQuestion sentence="失踪する実習生に対して対策してますか？" sentence2="入管庁によると、失踪は年々増加しており、2018年は前年比1963人増の9052人。19年上半期も4499人で、前年同期を256人上回っています。当組合では失踪の大きな原因である「多額の借金」を実習生にさせることなく、日本で実習業務に専念できる環境作りを送出し機関と共に行っております。実習実施者様につきましては、技能実習生受け入れ前に何度も打ち合わせをさせていただき、賃金トラブルが無いよう努めています。技能実習生の人権侵害の観点から実習実施者様の指導方法につきましてもアドバイスさせていただきます。実習生が失踪しない仕組み（コミュニティ）作りも合わせて行っておりますのでご安心ください。"/>
      <GinojisshuseiQuestionsQuestion sentence="入国までの期間はどのくらい？" sentence2="企業さまの求人ご依頼から配属までは約8～9カ月を有します。面接会からは約6～7カ月を見込んでいただいております。面接合格後に必要書類作成、在留資格認定申請の手続きに移行します。実習生は日本入国までの約4カ月の間、協定を締結している送出し機関で日本語指導、日本でのマナー、礼儀、文化、専門技能指導を学びます。企業様の要望もいただきながら配属時点でレベルの高い人材へと育成して参ります。 "/>
      <GinojisshuseiQuestionsQuestion sentence="派遣社員と技能実習生の違いは？" sentence2="外国人の派遣社員を受け入る場合は、直接の雇用関係が人材派遣会社（派遣元）との間にあるため、在留資格の申請をはじめ、手間や専門知識は必要ないといえますが、知らず知らずに不法就労者や就労できない在留資格を所持している人材を受け入れてしまうケースもゼロとはいえません。万が一の場合、派遣会社はもちろんですが、受入れ企業にも責任が問われる可能性があります。技能実習生の場合は実習実施者様が直接雇用することになりますし、技能実習法（外国人の技能実習の適正な実施及び技能実習生の保護に関する法律）のもとで制度の利用を行いますので、在留管理につきましても監理団体である私どもがサポートさせていただきます。"/>

      <h2 class="provisions">監理団体の業務の運営に関する規程</h2>
      <UiLine />
      <p>事業所名 JHI協同組合</p>
      <h3 class="regulation-title">第1 目的</h3>
      <p>
        この規定は、外国人の技能実習の適正な実施及び技能実習生の保護に関する法律及びその関係法令（以下「技能実習関係法令」という。）に基づいて、本事業所において監理事業を行うに当たって必要な事項について、規程として定めるものです。
      </p>
      <h3 class="regulation-title">第2 求人</h3>
      <p>
        1 本事業所は、別表1及び2の技能実習に関するものに限り、いかなる求人の申込みについてもこれを受理します。ただし、その申込みの内容が法令に違反する場合、その申込みの内容である賃金、労働時間その他の労働条件が通常の労働条件と比べて著しく不適当であると認める場合、又は団体監理型実習実施者等が労働条件等の明示をしない場合は、その申込みを受理しません。
      </p>
      <p>
        2 求人の申込みは、団体監理型実習実施者等（団体監理型実習実施者又は団体監理型実習実施者になろうとする者をいう。以下同じ。）又はその代理人の方が直接来所されて、所定の求人票によリお申込みください。なお、直接来所できないときは、郵便、電話、ファックス又は電子メールでも差し支えありません。
      </p>
      <p>
        3 求人申込みの際には、業務の内容、賃金、労働時間その他の労働条件をあらかじめ書面の交付又は電子メールの使用により明示してください。ただし、紹介の実施について緊急の必要があるため、あらかじめ書面の交付又は電子メールの使用による明示ができないときは、当該明示すべき事項をあらかじめこれらの方法以外の方法により明示してください。
      </p>
      <p>
        4 求人受付の際には、監理費（職業紹介費）を、別表3の監理費表に基づき申し受けます。いったん申し受けました手数料は、紹介の成否にかかわらずお返しいたしません。
      </p>
      <h3 class="regulation-title">第3 求職</h3>
      <p>
        1 本事業所は、（取扱職種の範囲等）の技能実習に関する限り、いかなる求職の申込みについてもこれを受理します。ただし、その申込みの内容が法令に違反するときは、これを受理しません。
      </p>
      <p>
        2 求職申込みは、団体監理型技能実習生等（団体監理型技能実習生又は団体監理型技能実習生になろうとする者をいう。以下同じ。）又はその代理人（外国の送出機関から求職の申込みの取次ぎを受けるときは、外国の送出機関）から、所定の求職票によりお申込みください。郵便、電話、ファックス又は電子メールで差し支えありません。
      </p>
      <h3 class="regulation-title">第4 技能実習に関する職業紹介</h3>
      <p>
        1 団体監理型技能実習生等の方には、職業安定法第2条にも規定される職業選択の自由の趣旨を踏まえ、その御希望と能力に応ずる職業に速やかに就くことができるよう極力お世話いたします。
      </p>
      <p>
        2 団体監理型実習実施者等の方には、その御希望に適合する団体監理型技能実習生等を極力お世話いたします。
      </p>
      <p>
        3 技能実習職業紹介に際しては、団体監理型技能実習生等の方に、技能実習に関する職業紹介において、従事することとなる業務の内容、賃金、労働時間その他の労働条件をあらかじめ書面の交付又は希望される場合には電子メールの使用により明示します。ただし、技能実習に関する職業紹介の実施について緊急の必要があるためあらかじめ書面の交付又は電子メールの使用による明示ができないときは、あらかじめそれらの方法以外の方法により明示を行います。
      </p>
      <p>
        4 団体監理型技能実習生等の方を団体監理型実習実施者等に紹介する場合には、紹介状を発行します。その紹介状を持参して団体監理型実習実施者等との面接を行っていただきます。
      </p>
      <p>
        5 いったん求人、求職の申込みを受けた以上、責任をもって技能実習に関する職業紹介の労をとります。
      </p>
      <p>
        6 本事業所は、労働争議に対する中立の立場をとるため、同盟罷業又は作業閉鎖の行われている間は団体監理型実習実施者等に、技能実習に関する職業紹介をいたしません。
      </p>
      <p>
        7 就職が決定しましたら求人された方から監理費（職業紹介費）を、別表3の監理費表に基づき申し受けます。
      </p>
      <h3 class="regulation-title">第5 団体監理型技能実習の実施に関する監理</h3>
      <p>
        1 団体監理型実習実施者が認定計画に従って技能実習を行わせているか等、監理責任者の指揮の下、主務省令第52条第1号イからホまでに定める方法（団体監理型技能実習生が従事する業務の性質上当該方法によることが著しく困難な場合にあっては、他の適切な方法）によって3か月に1回以上の頻度で監査を行うほか、実習認定の取消し事由に該当する疑いがあると認めたときは、直ちに監査を行います。
      </p>
      <p>
        2 第1号団体監理型技能実習に係る実習監理にあっては、監理責任者の指揮の下、1か月に1回以上の頻度で、団体監理型実習実施者が認定計画に従って団体監理型技能実習を行わせているかについて実地による確認（団体監理型技能実習生が従事する業務の性質上当該方法によることが著しく困難な場合にあっては、他の適切な方法による確認）を行うとともに、団体監理型実習実施者に対し必要な指導を行います。
      </p>
      <p>
        3 技能実習を労働力の需給の調整の手段と誤認させるような方法で、団体監理型実習実施者等の勧誘又は監理事業の紹介をしません。
      </p>
      <p>
        4 第一号団体監理型技能実習にあっては、認定計画に従って入国後講習を実施し、かつ、入国後講習の期間中は、団体監理型技能実習生を業務に従事させません。
      </p>
      <p>
        5 技能実習計画作成の指導に当たって、団体監理型技能実習を行わせる事業所及び団体監理型技能実習生の宿泊施設を実地に確認するほか、主務省令第52条第8号イからハに規定する観点から指導を行います。
      </p>
      <p>
        6 技能実習生の帰国旅費（第3号技能実習の開始前の一時帰国を含む。）を負担するとともに技能実習生が円滑に帰国できるよう必要な措置を講じます。
      </p>
      <p>
        7 団体監理型技能実習生との間で認定計画と反する内容の取決めをしません。
      </p>
      <p>
        8 実習監理を行っている団体監理型技能実習生からの相談に適切に応じるとともに、団体監理型実習実施者及び団体監理型技能実習生への助言、指導その他の必要な措置を講じます。
      </p>
      <p>
        9 本事業所内に監理団体の許可証を備え付けるとともに、本事業所内の一般の閲覧に便利な場所に、本規程を掲示します。
      </p>
      <p>
        10 技能実習の実施が困難となった場合には、技能実習生が引き続き技能実習を行うことを希望するものが技能実習を行うことができるよう、他の監理団体等との連絡調整等を行います。
      </p>
      <p>
        11 上記のほか、技能実習関係法令に従って業務を実施します。
      </p>
      <h3 class="regulation-title">第6 監理責任者</h3>
      <p>1 本事業所の監理責任者は、山崎静香です。</p>
      <p>2 監理責任者は、以下に関する事項を統括管理します。</p>
      <p>(1) 団体監理型技能実習生の受入れの準備</p>
      <p>(2) 団体監理型技能実習生の技能等の修得等に関する団体監理型実習実施者への指導及び助言並びに団体監理型実習実施者との連絡調整</p>
      <p>(3) 団体監理型技能実習生の保護</p>
      <p>(4) 団体監理型実習実施者等及び団体監理型技能実習生等の個人情報の管理</p>
      <p>(5) 団体監理型技能実習生の労働条件、産業安全及び労働衛生に関し、技能実習責任者との連絡調整に関すること</p>
      <p>(6) 国及び地方公共団体の機関、機構その他関係機関との連絡調整</p>
      <h3 class="regulation-title">第7 管理費の徴収</h3>
      <p>1 監理費は、団体監理型実習実施者等へあらかじめ用途及び金額を明示した上で徴収します。</p>
      <p>2 監理費（職業紹介費）は、団体監理型実習実施者等から求人の申込みを受理した時以降に当該団体監理型実習実施者等から、別表3の監理費表に基づき申し受けます。その額は、団体監理型実習実施者等と団体監理型技能実習生等との間における雇用関係の成立のあっせんに係る事務に要する費用（募集及び選抜に要する人件費、交通費、外国の送出機関へ支払う費用その他の実費に限る。）の額を超えない額とします。</p>
      <p>
        3 監理費（講習費）は、入国前講習に要する費用にあっては入国前講習の開始日以降に、入国後講習に要する費用にあっては入国後講習の開始日以降に、団体監理型実習実施者等から、別表3の監理費表に基づき申し受けます。その額は、監理団体が実施する入国前講習及び入国後講習に要する費用（監理団体が支出する施設使用料、講師及び通訳人への謝金、教材費、第一号団体監理型技能実習生に支給する手当その他の実費に限る。）の額を超えない額とします。
      </p>
      <p>
        4 監理費（監査指導費）は、団体監理型技能実習生が団体監理型実習実施者の事業所において業務に従事し始めた時以降一定期間ごとに当該団体監理型実習実施者から、別表3の監理費表に基づき申し受けます。その額は、団体監理型技能実習の実施に関する監理に要する費用（団体監理型実習実施者に対する監査及び指導に要する人件費、交通費その他の実費に限る。）の額を超えない額とします。
      </p>
      <p>
        5 監理費（その他諸経費）は、当該費用が必要となった時以降に団体監理型実習実施者等から、別表の監理費表に基づき申し受けます。その額は、その他技能実習の適正な実施及び技能実習生の保護に資する費用（実費に限る。）の額を超えない額とします。
      </p>
      <h3 class="regulation-title">第8 その他</h3>
      <p>
        1 本事業所は、国及び地方公共団体の機関であって技能実習に関する事務を所掌するもの、外国人技能実習機構その他関係機関と連携を図りつつ、当該事業に係る団体監理型実習実施者等又は団体監理型技能実習生等からの苦情があった場合には、迅速に、適切に対応いたします。
      </p>
      <p>
        2 雇用関係が成立しましたら、団体監理型実習実施者等、団体監理型技能実習生等の両から本事業所に対して、その報告をしてください。また、技能実習に関する職業紹介されたにもかかわらず、雇用関係が成立しなかったときにも同様に報告をしてください。
      </p>
      <p>
        3 本事業所は、団体監理型技能実習生等の方又は団体監理型実習実施者等から知り得た個人的な情報は個人情報適正管理規程に基づき、適正に取り扱います。
      </p>
      <p>
        4 本事業所は、団体監理型技能実習生等又は団体監理型実習実施者等に対し、その申込みの受理、面接、指導、技能実習に関する職業紹介等の業務について、人種、国籍、信条、性別、社会的身分、門地、従前の職業、労働組合の組合員であること等を理由として差別的な取扱いは一切いたしません。
      </p>
      <p>
        5 本事業所の取扱職種の範囲等は、別表1及び2のとおりです。
      </p>
      <p>
        6 本事業所の業務の運営に関する規定は、以上のとおりですが、本事業所の業務は、全て技能実習関係法令に基づいて運営されますので、御不審の点は係員に詳しくお尋ねください。
      </p>
      <p class="left-text">平成31年5月5日</p>
      <p class="left-text">代表者代表理事  加藤 司</p>

      <img class="regulation_management_costs_half_img" :src="`${rootUrl}/img/JHI-h_regulation_management_costs_003.jpg`" alt="取扱職種表" />
      <img class="regulation_management_costs_img" :src="`${rootUrl}/img/JHI-h_regulation_management_costs_004.svg`" alt="管理費表" />

      <UiDetailButton :path="`/`" link="トップページに戻る"/>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
import GinojisshuseiQuestionsQuestion from "@/components/Ginojisshusei/Questions/Question.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "GinojisshuseiQuestions",
  components: {
    UiLine,
    GinojisshuseiQuestionsQuestion,
    UiDetailButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.provisions {
  padding-top: 163px;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}
.regulation-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.left-text {
  text-align: end;
}
.regulation_management_costs_half_img {
  display: block;
  width: 50%;
  margin: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.regulation_management_costs_img {
  display: block;
  width: 100%;
  margin-top: 20px;
}
</style>