<template>
  <div class="tokuteiginoshien-acceptance">
    <h1>特定技能外国人の<br class="sp-kaigyo">受け入れ</h1>

    <UiLine />

    <img :src="`${rootUrl}/img/JHI-h_011.jpg`" alt="特定技能外国人の受け入れ" />

    <div class="box">
      <div class="sentence">
        <p>
          特定技能外国人を雇用するためには、外国人本人との直接雇用が原則となります。
          特定技能所属機関（貴社）は、自社で特定技能外国人の支援計画の策定およびその実施をする必要があります。<br> 
          その業務は任意で登録支援機関へ委託することが可能です。
          登録支援機関の役割として特定技能外国人に対する支援を計画し実施することですが、当登録支援機関としても、確実でスムーズな手続きができるよう支援させていただきます。
          特定技能外国人受入れの方法としては、現在、技能実習生として受入れている外国人を特定技能に、過去に受入れしていた技能実習生（帰国者）を呼寄せて特定技能に、その他、特定技能の条件を満たした外国人の受入れ等、いくつかのパターンがあり、企業様のニーズにあわせ確実に支援させていただきます。<br>
          特定技能で雇用できる職種には制限があります。<br>
          メリット、デメリットもあわせましてご説明させていただきますのでお気軽にお問合わせください。
        </p>
      </div>
      <div class="img">
        <img
          :src="`${rootUrl}/img/JHI-h_012.jpg`"
          alt="特定技能外国人の受け入れの図"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";

export default {
  name: "TokuteiginoshienAcceptance",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  @media screen and (max-width: 767px){
    flex-direction: column;
  }

  .sentence {
    width: 46%;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .img {
    width: 46%;

    @media screen and (max-width: 767px){
      width: 100%;
    }
    
    img {
      width: 100%;
    }
  }
}
</style>