<template>
  <div class="tokuteiginoshien-industry">
    <h3>受け入れ可能14業種</h3>
    <div class="circle">
      <div class="box">
        <div class="green">
          <p>ビルクリーニング</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p>素形材産業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>産業機械製造業</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p class=" plus">電気・<br class="kaigyo" />電子情報関連産業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>建設業</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p>造船・船用業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>外食業</p>
        </div>
      </div>
    </div>

    <div class="circle">
      <div class="box">
        <div class="blue">
          <p>自動車整備業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>航空業</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p>宿泊業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>農業</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p>漁業</p>
        </div>
      </div>
      <div class="box">
        <div class="green">
          <p>飲食料品製造業</p>
        </div>
      </div>
      <div class="box">
        <div class="blue">
          <p>介護</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokuteiginoshienIndustry",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--green);
  border-bottom: solid 1px var(--black);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  height: 60px;

  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.circle {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .box {
    position: relative;
    width: 13%;
    margin: 1%;

    @media screen and (max-width: 767px) {
      width: 98%;
    }
    .green,
    .blue {
      border-radius: 50%;
      width: 100%;
      padding-top: 100%;
      position: relative;

      @media screen and (max-width: 767px) {
        border-radius: initial;
        padding-top: 10%;
        padding-bottom: 10%;
        margin-bottom: 10px;
      }
      p {
        position: absolute;
        top: 44%;
        width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        line-height: 1.5em;



        @media screen and (max-width: 767px) {
          top: 23%;

          .kaigyo {
            display: none;
          }
        }

        // @media screen and (min-width: 1120px) and(max-width: 1150px) {
        //   font-size: 13px;
        // }
        @media screen and (min-width: 767px) and(max-width: 1120px) {
          font-size: 12px;
          top: 43%;
        }
      }
    }

    .green {
      background-color: var(--green);
    }
  }
  .blue {
    background-color: rgb(49, 90, 141);

    .plus{
      top: 30%!important;
    }
  }

 
}
</style>