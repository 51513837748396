<template>
  <div class="access">

    <div class="wrap">
      <h1 class="space-1">事業所情報</h1>
      <HomeInformation2/>
    </div>

    <div class="wrap">
      <AccessPhilosophy class="space-1" />
    </div>

    <div class="wrap">
      <HomeAccess class="space-1" />
      <UiDetailButton :path="`/`" link="トップページに戻る"/>
    </div>
   


    
  </div>
</template>

<script>
import HomeInformation2 from "@/components/Home/Information2.vue";
import HomeAccess from "@/components/Home/Access.vue";
import AccessPhilosophy from "@/components/Access/Philosophy.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";


export default {
  name: "Access",
  components: {
    HomeInformation2,
    AccessPhilosophy,
    HomeAccess,
    UiDetailButton,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  width: 100%;
}


</style>