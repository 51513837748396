<template>
  <div class="bar">
    <div class="green-bar">
      <p v-html="title"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiBar",
  components: {},
  props: {
    title: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.green-bar {
  background-color: var(--green);
  width: 100%;
  color: white;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  padding: 13px;
  margin-top: 100px;
  margin-bottom: 20px;
}
</style>