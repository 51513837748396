<template>
  <div class="ginojisshusei-work">
    <UiBar title="技能実習制度<br class='sp-kaigyo'>移行対象職種・作業" />

    <p>
      技能実習生は、技能実習2号への移行を前提として、指定の職種と作業につき、全国各地で受入れることができます。
      <br />技能実習の２号移行対象職種・作業については以下の表となります。
    </p>

    <p class="title">■技能実習制度 移行対象職種・作業一覧(82職種148作業)</p>

    <img :src="`${rootUrl}/img/JHI-h_028.webp`" alt="技能実習制度" />
    <p class="sp">※スマートフォンからの閲覧時は、ピンチアウトして拡大して閲覧してください。</p>
  </div>
</template>

<script>
import UiBar from "@/components/Ui/Bar.vue";
export default {
  name: "GinojisshuseiWork",
  components: {
    UiBar,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.title {
  color: var(--green);
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.date{
  text-align: right;
}

.sp{
  display: none;

  @media screen and (max-width: 767px){
    display: initial;
    font-size: 13px;
  }

}
</style>