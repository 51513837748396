<template>
  <div class="home-access">
    <h2>アクセス</h2>
    <UiLine />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d577.9264780854995!2d137.05335042113344!3d34.975506065255495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60049be37e986023%3A0x4bf035a01d104d2d!2z44CSNDQ2LTAwNzYg5oSb55-l55yM5a6J5Z-O5biC576O5ZyS55S677yS5LiB55uu77yR77yY4oiS77yT!5e0!3m2!1sja!2sjp!4v1621316289814!5m2!1sja!2sjp"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>

    <div class="address">
      <p>住所：<br class="sp-kaigyo">愛知県安城市美園町二丁目18番地3</p>
      <p class="green">三河安城駅から徒歩15分に当組合事務所があります。</p>
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "access",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.address {
  padding-top: 20px;
}
.green {
  color: var(--green);
}
</style>