<template>
  <div class="home-aboutus">
    <h1>JHI協同組合について</h1>
    <UiLine />

    <div class="aboutus">
      <img :src="`${rootUrl}/img/JHI-h_002.jpg`" alt="JHI協同組合について" />
      <p>
        業務内容としては「外国人技能実習生の共同受入事業」と「共同購買事業」に力を入れて活動を行っており、拠点は産業がとても多い愛知県に事務所があります。
        組合員様や技能実習生が全員が笑顔で幸せになれるような活動を行っております。
      </p>
    </div>
  </div>
</template>

<script>
import UiLine from "@/components/Ui/Line.vue";
export default {
  name: "HomeAboutus",
  components: {
    UiLine,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutus {
  img {
    width: 100%;

  }
  P {
    padding-top: 10px;
  }
}
</style>