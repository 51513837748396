<template>
  <div class="home">
    <div class="pc">
      <img
        class="top"
        :src="`${rootUrl}/img/JHI-h_001-2.jpg`"
        alt="JHI協同組合ＰＣトップ"
      />
    </div>

    <div class="sp">
      <img
        :src="`${rootUrl}/img/JHI-sp_top001.jpg`"
        alt="JHI協同組合ＳＰトップ"
      />
    </div>


 

    <div class="wrap">
      <HomeAboutus class="space-1" />
    </div>

    <div class="wrap">
      <HomeContent class="space-2" />
    </div>

    <HomeReasons class="space-2" />

    <div class="wrap">
      <h2 class="space-2">事業所情報</h2>
      <HomeInformation />
      <UiDetailButton class="button" :path="`/access`" link="詳細はコチラ" />
    </div>

    <div class="wrap">
      <HomeAccess class="space-3" />
    </div>

      


  </div>
</template>

<script>
import HomeAboutus from "@/components/Home/Aboutus.vue";
import HomeContent from "@/components/Home/Content.vue";
import HomeReasons from "@/components/Home/Reasons.vue";
import HomeInformation from "@/components/Home/Information.vue";
import HomeAccess from "@/components/Home/Access.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";


export default {
  name: "Home",
  components: {
    HomeAboutus,
    HomeContent,
    HomeReasons,
    HomeInformation,
    HomeAccess,
    UiDetailButton,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.pc {
  .top {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    display: none!important;
  }

  
}
.sp {
    display: none;
    width: 100%;
    height: auto;

    @media screen and (max-width: 767px) {
      display: initial;
    }
  }
.button {
  width: 59%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 767px) {
    margin-right: 20px;
  }


}


.space-3{
  padding-top: 163px; 
  padding-bottom: 163px;

  @media screen and (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 30px;
  }
}
</style>